<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("STOCK_PURCHASE_ORDER_PRODUCTION") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <BackButton></BackButton>
          <v-btn
            color="btn-success"
            class="btn btn-light-success font-weight-bolder mr-2"
            @click="getXML"
          >
            <i class="flaticon-list-1"></i>
            {{ $t("DOWNLOAD") }}
          </v-btn>
        </div>
      </template>
      <v-card>
        <template>
          <b-row class="mx-3">
            <b-col :lg="6" :md="12">
              <v-autocomplete
                :items="stores"
                :label="$t('STORE')"
                item-text="name"
                item-value="id"
                hide-details
                clearable
                v-model="filters.warehouseId"
                @click:clear="clearFilter('warehouseId')"
              ></v-autocomplete>
            </b-col>
            <b-col class="text-right" :lg="6" :md="12">
              <v-tooltip color="primary" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="doSearch"
                  >
                    <v-icon dark>search</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("SEARCH") }}</span>
              </v-tooltip>
            </b-col>
          </b-row>
        </template>
      </v-card>
      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <v-overlay :value="!firstLoader && isPageLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <template v-slot:footer></template>
    </b-card>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <b-row>
        <b-col :lg="6" :md="12">
          <v-card>
            <v-data-table
              :headers="headersStoresRequests"
              :items="storesRequests"
              :loading="isPageLoading"
              :hide-default-footer="true"
              @update:options="getDataFromApi()"
              loading-text="Loading... Please wait"
              class="stores-requests-table"
            >
              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>
              <template
                v-slot:item.order_requested_items_with_recipe.itemName="{
                  item
                }"
              >
                <v-list>
                  <v-list-item
                    v-for="item in item.order_requested_items_with_recipe"
                    active-color="primary"
                    v-bind:key="item.itemName"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.itemName }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
              <template
                v-slot:item.order_requested_items_with_recipe.sumUnitValue="{
                  item
                }"
              >
                <v-list>
                  <v-list-item
                    v-for="(rq,
                    index) in item.order_requested_items_with_recipe"
                    active-color="primary"
                    v-bind:key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ rq.sumUnitValue | formatBalance }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
              <template
                v-slot:item.order_requested_items_with_recipe.sumDoughTrays="{
                  item
                }"
              >
                <v-list>
                  <v-list-item
                    v-for="(rq,
                    index) in item.order_requested_items_with_recipe"
                    active-color="primary"
                    v-bind:key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-show="rq.isDough">
                        {{ rq.sumDoughTrays | formatBalance }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </v-data-table>
          </v-card>
        </b-col>
        <b-col :lg="6" :md="12">
          <v-card>
            <v-data-table
              :headers="headersSumDoughItems"
              :items="sumDoughItems"
              :loading="isPageLoading"
              :hide-default-footer="true"
              loading-text="Loading... Please wait"
              class="sum-dough-items-table"
            >
              <template v-slot:item.weight="{ item }">
                {{ item.weight }}
              </template>
              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item.sumDoughs="{ item }">
                {{ item.sumDoughs | formatBalance }}
              </template>
              <template v-slot:item.sumDoughTrays="{ item }">
                {{ item.sumDoughTrays | formatBalance }}
              </template>
              <template v-slot:item.mixers="{ item }">
                {{ item.mixers | formatBalance }}
              </template>

              <template slot="body.append">
                <tr class="previewTr">
                  <th>Общо</th>
                  <th></th>
                  <th>
                    {{ totalDoughs }}
                  </th>
                  <th>
                    {{ totalTrays }}
                  </th>
                  <th>
                    {{ totalMixers | formatBalance }}
                  </th>
                </tr>
              </template>
            </v-data-table>
          </v-card>
          <div class="py-6"></div>
          <v-card>
            <v-data-table
              :headers="headersSumIngredients"
              :items="sumIngredients"
              :loading="isPageLoading"
              :hide-default-footer="true"
              loading-text="Loading... Please wait"
              class="sum-dough-items-table"
            >
              <template v-slot:item.ingredientName="{ item }">
                {{ item.ingredientName }}
                <small>({{ item.short_name }})</small>
              </template>
              <template v-slot:item.ingredientWeight="{ item }">
                {{ item.ingredientWeight | formatBalance }}
              </template>
            </v-data-table>
          </v-card>
          <div class="py-6"></div>
          <v-card>
            <v-data-table
              :headers="headersItemsWithRecipe"
              :items="sumItemsWithRecipe"
              :loading="isPageLoading"
              :hide-default-footer="true"
              loading-text="Loading... Please wait"
              class="sum-dough-items-table"
            >
              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item.sumUnitValue="{ item }">
                {{ item.sumUnitValue | formatBalance }}
              </template>
            </v-data-table>
          </v-card>
        </b-col>
      </b-row>
    </b-card>
    <v-skeleton-loader
      v-if="isPageLoading"
      :loading="isPageLoading"
      type="table"
    ></v-skeleton-loader>
    <v-overlay :value="!firstLoader && isPageLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

//Audit Module
import {
  FETCH_STOCK_PURCHASE_ORDER_PRODUCTION,
  STOCK_PURCHASE_ORDER_PRODUCTION_XLS
} from "@/modules/stock/store/stock.module";

import { FETCH_STORES_WAREHOUSES } from "@/core/services/store/shared_store.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import moment from "moment";

export default {
  name: "StockPurchaseOrderProduction",
  mixins: [permissionMixin],
  data() {
    return {
      firstLoader: true,
      isPageLoading: true,
      sumDoughItems: [],
      sumItemsWithRecipe: [],
      sumIngredients: [],
      storesRequests: [],
      totalDoughs: 0,
      totalTrays: 0,
      totalMixers: 0,
      stores: [],
      filters: {
        warehouseId: null
      },
      columns: {},
      cancelSource: null,
      headersStoresRequests: [
        {
          text: this.$t("STORE"),
          value: "name"
        },
        {
          text: this.$t("ITEM_PRODUCT"),
          value: "order_requested_items_with_recipe.itemName",
          sortable: false
        },
        {
          text: this.$t("DOUGHS"),
          value: "order_requested_items_with_recipe.sumUnitValue",
          sortable: false
        },
        {
          text: this.$t("DOUGH_TRAYS"),
          value: "order_requested_items_with_recipe.sumDoughTrays",
          sortable: false
        }
      ],
      headersSumDoughItems: [
        {
          text: this.$t("WEIGHT"),
          value: "weight",
          sortable: false
        },
        {
          text: "",
          value: "name",
          sortable: false
        },
        {
          text: this.$t("DOUGHS"),
          value: "sumDoughs",
          sortable: false
        },
        {
          text: this.$t("DOUGH_TRAYS"),
          value: "sumDoughTrays",
          sortable: false
        },
        {
          text: this.$t("MIXERS"),
          value: "mixers",
          sortable: false
        }
      ],
      headersSumIngredients: [
        {
          text: this.$t("INGREDIENTS"),
          value: "ingredientName"
        },
        {
          text: this.$t("WEIGHT"),
          value: "ingredientWeight",
          sortable: false
        }
      ],
      headersItemsWithRecipe: [
        {
          text: this.$t("ITEM_PRODUCT"),
          value: "name"
        },
        {
          text: this.$t("ORDERED"),
          value: "sumUnitValue",
          sortable: false
        }
      ]
    };
  },
  components: {},
  watch: {
    storesParams(value) {
      let vm = this;
      vm.filters.warehouseId = null;
      vm.getStores();
    }
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock Purchase Orders Production" }
    ]);
    vm.getStores();
  },
  computed: {
    ...mapGetters(["isLoadingStock"]),
    params() {
      return {
        warehouseId: this.filters.warehouseId
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    },
    maxDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    clearFilter(key) {
      let vm = this;
      vm.$nextTick(function() {
        this.$set(vm.filters, key, null);
      });
    },
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      vm.getDataFromApi();
    },
    getDataFromApi() {
      let vm = this;
      //copy current params to modify
      let params = this.params;
      let apiParams = vm.transformUrlParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_STOCK_PURCHASE_ORDER_PRODUCTION, apiParams)
        .then(({ data }) => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.sumDoughItems = data.requestedDoughItems
              ? data.requestedDoughItems.sumDoughItems
              : [];
            vm.sumItemsWithRecipe = data.requestedDoughItems
              ? data.requestedDoughItems.sumItemsWithRecipe
              : [];
            vm.sumIngredients = data.requestedDoughItems
              ? data.requestedDoughItems.sumIngredients
              : [];
            vm.totalDoughs = data.requestedDoughItems
              ? data.requestedDoughItems.totalDoughs
              : [];
            vm.totalMixers = data.requestedDoughItems
              ? data.requestedDoughItems.totalMixers
              : [];
            vm.totalTrays = data.requestedDoughItems
              ? data.requestedDoughItems.totalTrays
              : [];
            vm.storesRequests = data.stores;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "stock-purchase-orders-list" });
          }
        });
    },
    getXML() {
      let vm = this;
      vm.isLoadingStoreDailyTasks = true;
      let params = {
        warehouseId: vm.filters.warehouseId
      };
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(STOCK_PURCHASE_ORDER_PRODUCTION_XLS, apiParams)
        .then(data => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              "stock-purchase-order-production-" +
              moment().format("DD-MM-YYYY");
            link.click();
            link.remove();
            vm.dialogMZxml = false;
            if (vm.isPageLoading == true || vm.isXmlMzLoading == true) {
              vm.isPageLoading = false;
              vm.isXmlMzLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getStores() {
      let vm = this;
      let params = {};
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_WAREHOUSES, apiParams)
        .then(data => {
          this.$set(vm, "stores", data.data);
          vm.filters.warehouseId = data.data[0].id;
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    }
  }
};
</script>
